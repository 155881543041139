/*
 * @Author: lsw lsw_0524@163.com
 * @Date: 2023-11-09 14:27:33
 * @LastEditors: lsw lsw_0524@163.com
 * @LastEditTime: 2024-01-05 17:23:11
 * @FilePath: \yiyun_projectd:\webWork\ReactApplication\microcode-benefits-service-provider\src\App.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { Route, Routes } from "react-router-dom";
import Login from "./views/login";
import AddServiceProvider from "./views/addServiceProvider";
import AddHeHuo from "./views/addHeHuo";
import AddStore from "./views/addStore";
import ActivitySignIn from "./views/activitySignIn";
import ReviewProgress from "./views/reviewProgress";
import AddHoster from "./views/AddHoster";
import ScanCode from "./views/scanCode";
import BusinessList from "./views/businessList";
import BusinessInfo from "./views/businessInfo";
import ChooseAddType from "./views/chooseAddType";
import HosterProgress from "./views/hosterProgress";
function App() {
  return (
    <>
      <Routes>
        <Route path="/" exact element={<Login />} />
        <Route path="/ChooseAddType" exact element={<ChooseAddType />} />
        <Route
          path="/addServiceProvider"
          exact
          element={<AddServiceProvider />}
        />
        <Route path="/AddHeHuo" exact element={<AddHeHuo />} />
        <Route path="/AddStore" exact element={<AddStore />} />
        <Route path="/ActivitySignIn" exact element={<ActivitySignIn />} />
        <Route path="/ReviewProgress" exact element={<ReviewProgress />} />
        <Route path="/HosterProgress" exact element={<HosterProgress />} />
        <Route path="/AddHoster" exact element={<AddHoster />} />
        <Route path="/ScanCode" exact element={<ScanCode />} />
        <Route path="/businessList" exact element={<BusinessList />} />
        <Route path="/businessInfo" exact element={<BusinessInfo />} />
      </Routes>
    </>
  );
}

export default App;
