import React, { useState, useEffect } from "react";
import { Input, Button, Image, Toast } from "antd-mobile";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "../../util/axios";
import { handleLocationPath } from "../../util/utils";
import "./index.scss";
function Login() {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  let { type } = handleLocationPath();
  let [state, setState] = useState({
    staff_mobile: "",
    password: "",
    utoken: localStorage.getItem("utoken"),
  });
  let login = () => {
    if (!state.staff_mobile) return Toast.show({ content: "请输入手机号码" });
    if (!state.password) return Toast.show({ content: "请输入登录密码" });
    axios.post("/Staff/Login/login", state).then((res) => {
      if (res.code == 0) {
        sessionStorage.setItem("token", res.result.token);
        if (type) {
          navigate("/businessList");
        } else {
          navigate("/chooseAddType");
        }
      } else {
        Toast.show({
          content: res.msg,
        });
      }
    });
  };
  let authFn = () => {
    let path = window.location.href;
    // wx24a467f22dd9bc98
    let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx24a467f22dd9bc98&component_appid=wx3e81c0bf58ee455c&redirect_uri=${encodeURIComponent(
      path
    )}&response_type=code&scope=snsapi_userinfo#wechat_redirect`;
    window.location.replace(url);
  };
  let getUtoken = () => {
    let obj = handleLocationPath();
    sessionStorage.setItem("u_id", obj.uniacid);
    axios
      .post("/Api/WxShop/WechatUser/getUtoken", {
        code: obj.code,
        uniacid: obj.uniacid,
      })
      .then((res) => {
        if (res.code == 0) {
          localStorage.setItem("utoken", res.result.utoken);
        } else {
          Toast.show({
            content: res.msg,
          });
        }
      });
  };
  useEffect(() => {
    // localStorage.setItem("utoken", "utoken_b7260e9d735d9066907b065e108d040e");
    if (!search.get("code")) {
      authFn();
    } else {
      // 已经授权过了
      getUtoken();
    }
  }, []);
  return (
    <>
      <div className="back">
        <Image src={require("../../image/back.png")} />
      </div>
      <div className="login">
        <div className="form-item">
          <Image src={require("../../image/user.png")} />
          <Input
            value={state.staff_mobile}
            onChange={(e) => setState({ ...state, staff_mobile: e })}
            placeholder="请输入手机号码"
          />
        </div>
        <div className="form-item">
          <Image src={require("../../image/pass.png")} />
          <Input
            value={state.password}
            onChange={(e) => setState({ ...state, password: e })}
            placeholder="请输入登录密码"
            type="password"
          />
        </div>
        <div className="btn">
          <Button block color="primary" size="large" onClick={() => login()}>
            登录
          </Button>
        </div>
      </div>
    </>
  );
}

export default Login;
