/*
 * @Author: lsw lsw_0524@163.com
 * @Date: 2024-01-24 15:37:28
 * @LastEditors: lsw lsw_0524@163.com
 * @LastEditTime: 2024-05-20 18:12:59
 * @FilePath: /yzj_provide/src/views/scanCode/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "../../util/axios";
import { handleLocationPath } from "../../util/utils";
import { Toast } from "antd-mobile";
import "./index.scss";
function ScanCode() {
  const { state } = useLocation();
  const utoken = localStorage.getItem("utoken");
  const [u_id, set_uId] = useState("");
  const [title, set_title] = useState("");
  let [qrcode_url, set_qrcode_url] = useState(null);
  let getInfo = () => {
    let obj = handleLocationPath();
    axios
      .post("/Api/JuTwitter/Facilitator/index", {
        ...obj,
        utoken: utoken,
      })
      .then((res) => {
        if (res.code == 0) {
          set_qrcode_url(res.result.qrcode_url);
        }
      });
  };
  let getUtoken = () => {
    let path = window.location.href;
    let obj = handleLocationPath();
    if (obj.code) {
      axios
        .post("/Api/WxShop/WechatUser/getUtoken", {
          code: obj.code,
          uniacid: obj.uniacid,
        })
        .then((res) => {
          if (res.code == 0) {
            localStorage.setItem("utoken", res.result.utoken);
            window.location.reload();
          } else {
            Toast.show({
              content: res.msg,
            });
          }
        });
    } else {
      // wx24a467f22dd9bc98
      let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx24a467f22dd9bc98&component_appid=wx3e81c0bf58ee455c&redirect_uri=${encodeURIComponent(
        path
      )}&response_type=code&scope=snsapi_userinfo#wechat_redirect`;
      window.location.replace(url);
    }
  };
  let getScanCode = () => {
    axios
      .post("/Staff/Facilitator/twitterSet", {
        uniacid: u_id,
        utoken: utoken,
      })
      .then((res) => {
        if (res.code == 0) {
          set_title(res.result.activity_title);
        }
      });
  };
  useEffect(() => {
    document.title = "扫码关注";
    if (!u_id) {
      let obj = handleLocationPath();
      if (obj.uniacid) {
        set_uId(obj.uniacid);
      } else {
        set_uId(sessionStorage.getItem("u_id"));
      }
    }
    if (!state) {
      utoken ? getInfo() : getUtoken();
    }
  }, []);
  useEffect(() => {
    if (u_id) {
      getScanCode();
    }
  }, [u_id]);
  return (
    <>
      <div
        className="scan-code"
        style={{
          backgroundImage: "url(" + require("../../image/back2.png") + ")",
        }}
      >
        <div className="code">
          <div>{title}</div>
          <div>消息动态提醒·收益全掌握</div>
          <div>
            <img src={state || qrcode_url} alt="" />
          </div>
          <div>长按识别二维码图片</div>
        </div>
      </div>
    </>
  );
}

export default ScanCode;
