import React, { useState, useEffect } from "react";
import { Input, Toast, Modal } from "antd-mobile";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "../../util/axios";
import { GetCharLength, handleLocationPath } from "../../util/utils";
import style from "./index.module.scss";
import _ from "lodash";
function Activation() {
  const [search] = useSearchParams();
  const [store_settle_in_annual_fee, set_store_settle_in_annual_fee] =
    useState(0);
  const [state, setState] = useState({
    contact_name: "",
    hoster_phone: "",
  });
  const navigate = useNavigate();
  let getInfo = () => {
    axios
      .post("/Api/JuTwitter/Hoster/info", {
        sn: localStorage.getItem("sn"),
        utoken: localStorage.getItem("utoken"),
      })
      .then((res) => {
        if (res.code === 0) {
          if (res.result && search.get("sn")) {
            navigate("/HosterProgress", {
              state: res.result.status,
            });
          }
          if (res.result) {
            setState(res.result);
          }
        } else {
          Modal.show({
            bodyStyle: { padding: "20px 0 0 0" },
            content: res.msg,
            closeOnMaskClick: !0,
            showCloseButton: !0,
          });
        }
      });
  };
  let submitPay = (id) => {
    axios
      .post("/Api/JuTwitter/Hoster/renewPay", {
        utoken: localStorage.getItem("utoken"),
        sn: localStorage.getItem("sn"),
        id,
      })
      .then((res) => {
        if (res.code == 0) {
          window.location.replace(res.result.payUrl);
        } else {
          Toast.show({
            content: res.msg,
          });
        }
      });
  };
  let submit = () => {
    try {
      if (!state.contact_name) throw "请输入主理人名称";
      if (GetCharLength(state.contact_name) > 16) throw "店铺名称不可超过8个字";
      if (!state.hoster_phone) throw "请输入主理人电话";
      axios
        .post("/Api/JuTwitter/Hoster/submit", {
          utoken: localStorage.getItem("utoken"),
          sn: localStorage.getItem("sn"),
          contact_name: state.contact_name,
          hoster_phone: state.hoster_phone,
        })
        .then((res) => {
          if (res.code === 0) {
            Toast.show({
              icon: "success",
              content: "提交成功",
            });
            if (search.get("is_need_pay")) {
              submitPay(res.result);
            } else {
              navigate("/HosterProgress", { state: 1 });
            }
          } else {
            Toast.show({
              content: res.msg,
            });
          }
        });
    } catch (e) {
      Toast.show({
        content: e,
      });
    }
  };

  let authFn = () => {
    let path = window.location.href;
    // wx24a467f22dd9bc98
    let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx24a467f22dd9bc98&component_appid=wx3e81c0bf58ee455c&redirect_uri=${encodeURIComponent(
      path
    )}&response_type=code&scope=snsapi_userinfo#wechat_redirect`;
    window.location.replace(url);
  };
  let getUtoken = () => {
    let obj = handleLocationPath();
    axios
      .post("/Api/WxShop/WechatUser/getUtoken", {
        code: obj.code,
        uniacid: 3,
      })
      .then((res) => {
        if (res.code == 0) {
          localStorage.setItem("utoken", res.result.utoken);
          getInfo();
          if (Number(localStorage.getItem("is_need_pay"))) {
            getPayPrice();
          }
        } else {
          Toast.show({
            content: res.msg,
          });
        }
      });
  };
  let getPayPrice = () => {
    axios
      .post("/Api/JuTwitter/Hoster/index", {
        sn: localStorage.getItem("sn"),
        utoken: localStorage.getItem("utoken"),
      })
      .then((res) => {
        if (res.code == 0) {
          set_store_settle_in_annual_fee(res.result.store_settle_in_annual_fee);
        } else {
          Toast.show({
            content: res.msg,
          });
        }
      });
  };
  useEffect(() => {
    document.title = "添加主理人";
    if (search.get("sn")) localStorage.setItem("sn", search.get("sn"));
    if (search.get("is_need_pay"))
      localStorage.setItem("is_need_pay", search.get("is_need_pay") || 0);
    if (!search.get("code") && !localStorage.getItem("utoken")) {
      authFn();
    }
    if (!localStorage.getItem("utoken")) {
      // 已经授权过了
      getUtoken();
    } else {
      if (Number(localStorage.getItem("is_need_pay"))) {
        getPayPrice();
      }
      getInfo();
    }
  }, []);
  return (
    <div className={style.content}>
      <div className={style.formInfo}>
        <div className={style.formItem}>
          <div className={style.title}>
            <span>*</span>
            主理人姓名
          </div>
          <div className={style.put}>
            <Input
              placeholder="请输入主理人姓名"
              value={state.contact_name}
              onChange={(e) => setState({ ...state, contact_name: e })}
            />
          </div>
        </div>
        <div className={style.formItem}>
          <div className={style.title}>
            <span>*</span>
            主理人电话
          </div>
          <div className={style.put}>
            <Input
              placeholder="请输入主理人电话"
              value={state.hoster_phone}
              onChange={(e) => setState({ ...state, hoster_phone: e })}
            />
          </div>
        </div>
      </div>
      <div className={style.btn} onClick={() => submit()}>
        {!search.get("is_need_pay")
          ? "确认提交"
          : `确认支付 ¥${store_settle_in_annual_fee}`}
      </div>
    </div>
  );
}

export default Activation;
