import axios from "./axios";
export const getDateformat = (time) => {
  let date = new Date(time * 1000);
  let year = date.getFullYear();
  let month =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  let d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  let h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  let m = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  let s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  return year + "-" + month + "-" + d + " " + h + ":" + m + ":" + s;
};

export const inspectPhone = (val) => {
  let myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
  if (!myreg.test(val)) {
    return false;
  } else {
    return true;
  }
};

export const handleLocationPath = () => {
  let path = window.location.href;
  let path_arr = path.split("?");
  let obj = {};
  if (path_arr.length > 1) {
    path_arr = path_arr[1].split("&");
    for (let i in path_arr) {
      let key = path_arr[i].split("=");
      obj[key[0]] = key[1];
    }
  }

  return obj;
};

export const GetCharLength = (str) => {
  var iLength = 0; //记录字符的字节数
  for (var i = 0; i < str.length; i++) {
    //遍历字符串中的每个字符
    if (str.charCodeAt(i) > 255) {
      //如果当前字符的编码大于255
      iLength += 2; //所占字节数加2
    } else {
      iLength += 1; //否则所占字节数加1
    }
  }
  return iLength; //返回字符所占字节数
};

export const wxConfig = () => {
  const script = document.createElement("script");
  script.src = "https://res.wx.qq.com/open/js/jweixin-1.6.0.js"; //微信的js文件
  script.async = true;
  document.body.appendChild(script);
  axios
    .post("/Api/WxShop/WechatUser/jssdk", {
      url: window.location.href.split("#")[0],
    })
    .then(({ result, code }) => {
      if (code == 0) {
        window.wx.config({
          debug: false, //改为true就是打开微信的debug 模式 微信开发者工具 会在上面弹窗 弹出errmsg 真机的话 会弹出类似ant-mobile的modal框一样的弹框 显示errmsg
          appId: result.appId, //微信后台绑定js接口安全域名的公众号的appid 可以让后端写成常量 记得一定让后端返回给你
          timestamp: result.timestamp, //时间戳 随机 后端自己决定 但是一定要让后端同学把这个参数返回给你 这种格式的时间戳1620543538
          nonceStr: result.nonceStr, //随机字符串 后端同学自己随机 记得让他给你返回这个字段
          signature: result.signature, //生成的签名 我们最重要的字段
          jsApiList: result.jsApiList, //jsapi 微信的一些功能 我这里吧全部都加上了，这个不能为空 至少要有一个
          openTagList: result.openTagList, // 微信返回给我们的开放标签 h5跳转微信小程序我们用 wx-open-launch-weapp这标签就可以了
        });
        window.wx.ready(function () {
          window.wx.onMenuShareAppMessage({
            title: "全真码☞让生活更惬意", // 分享标题
            desc: "全场景服务真的很实惠码上生活就在这里", // 分享描述
            link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl:
              "https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/icon.png", // 分享图标
            success: function () {
              console.log("分享成功");
            },
          });
          window.wx.onMenuShareTimeline({
            title: "全真码☞让生活更惬意", // 分享标题
            desc: "全场景服务真的很实惠码上生活就在这里", // 分享描述
            link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl:
              "https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/icon.png", // 分享图标
            success: function () {
              console.log("分享成功");
            },
          });
        });
        window.wx.error(function (res) {
          console.log(res);
        });
      }
    });
};
