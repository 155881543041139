import React, { useState, useEffect } from "react";
import { Input, Button, Toast } from "antd-mobile";
import axios from "../../util/axios";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import _ from "lodash";
function AddServiceProvider() {
  const navigate = useNavigate();
  let [state, setState] = useState({
    name: "",
    mobile: "",
    commission_ratio: "",
    total_qrcode: "",
  });

  let submit = () => {
    try {
      if (!state.name) throw "请输入合作商姓名";
      if (!state.mobile) throw "请输入合作商手机号";
      if (!state.commission_ratio) throw "请输入佣金比例";
      if (!state.total_qrcode) throw "请输入数量";
      axios.post("/Staff/ShopRegimental/addRegimental", state).then((res) => {
        if (res.code == 0) {
          navigate("/scanCode", { state: res.result.qrcode });
        } else {
          Toast.show({
            content: res.msg,
          });
        }
      });
    } catch (e) {
      Toast.show({
        content: e,
      });
    }
  };
  useEffect(() => {
    document.title = "添加合伙人";
  }, []);
  return (
    <>
      <div className="service-provider">
        <div className="form-item">
          <div className="title">合伙人姓名</div>
          <Input
            value={state.name}
            onChange={(e) => setState({ ...state, name: e })}
            placeholder="请输入合伙人姓名"
          />
        </div>
        <div className="form-item">
          <div className="title">合伙人手机号</div>
          <Input
            value={state.mobile}
            onChange={(e) => setState({ ...state, mobile: e })}
            placeholder="请输入合伙人手机号"
          />
        </div>
        <div className="form-item">
          <div className="title">佣金比例（%）</div>
          <Input
            value={state.commission_ratio}
            onChange={(e) => setState({ ...state, commission_ratio: e })}
            placeholder="请输入佣金比例"
          />
        </div>
        <div className="form-item">
          <div className="title">添加免费二维码数量</div>
          <Input
            value={state.total_qrcode}
            onChange={(e) => setState({ ...state, total_qrcode: e })}
            placeholder="请输入数量"
          />
        </div>
        <div className="btn">
          <Button block color="primary" size="large" onClick={() => submit()}>
            确认
          </Button>
        </div>
      </div>
    </>
  );
}

export default AddServiceProvider;
