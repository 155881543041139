import React from 'react'
import ReactDOM from 'react-dom/client'
import 'lib-flexible'
import { BrowserRouter } from 'react-router-dom'
import './index.scss'
import App from './App'
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <BrowserRouter>
    {/* <React.StrictMode>
        <App />
      </React.StrictMode> */}
    <App />
  </BrowserRouter>
)
