/*
 * @Author: lsw lsw_0524@163.com
 * @Date: 2023-12-29 14:36:11
 * @LastEditors: lsw lsw_0524@163.com
 * @LastEditTime: 2023-12-29 16:16:22
 * @FilePath: \yiyun_projectd:\webWork\ReactApplication\microcode-benefits-service-provider\src\views\activitySignIn\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useState, useEffect } from "react";
import { Html5Qrcode, Html5QrcodeScanner } from "html5-qrcode";
import { Image } from "antd-mobile";
import { wxConfig } from "../../util/utils";
import "./index.scss";

function ActivitySignIn() {
  let scanner = null;
  const getQrcode = () => {
    const html5QrCode = new Html5QrcodeScanner("reader", {
      fps: 10,
      qrbox: { width: 250, height: 250 },
    });

    html5QrCode.render((decodedText, decodedResult) => {
      console.log(decodedText, decodedResult);
    });
  };
  function getCameras() {
    scanner = new Html5Qrcode("reader");
    start();
  }
  function start() {
    //environment后置 user前置
    scanner
      .start(
        { facingMode: "environment" },
        {
          fps: 2,
          qrbox: { width: 250, height: 250 },
        },
        (decodedText, decodedResult) => {
          console.log(11111);
          // this.$toast(decodedText);
          //decodedText扫描的结果
          //   this.$emit("ok", decodedText);
        }
      )
      .catch((err) => {
        // this.$emit("err", err);
      });
  }
  function stop() {
    scanner
      .stop()
      .then((ignore) => {
        // QR Code scanning is stopped.
        console.log("QR Code scanning stopped.");
      })
      .catch((err) => {
        // Stop failed, handle it.
        console.log("Unable to stop scanning.");
      });
  }

  useEffect(() => {
    wxConfig();
    document.title = "扫码核销";
    getCameras();
  }, []);

  return (
    <>
      <div id="reader"></div>
    </>
  );
}

export default ActivitySignIn;
