import React, { useState, useEffect, useRef } from "react";
import { Image, Input, Toast, Modal } from "antd-mobile";
import { CloseCircleFill } from "antd-mobile-icons";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { RightOutline } from "antd-mobile-icons";
import axios from "../../util/axios";
import { GetCharLength, handleLocationPath } from "../../util/utils";
import style from "./index.module.scss";
import addImg from "../../image/add_img.png";
import _ from "lodash";
import City from "../../components/city";
function Activation() {
  const [search] = useSearchParams();
  const [store_settle_in_annual_fee, set_store_settle_in_annual_fee] =
    useState(0);
  const [state, setState] = useState({
    merchant_name: "",
    contact_name: "",
    store_phone: "",
    store_site: "",
    store_logo: "",
    business_license: "",
    province_id: "",
    city_id: "",
    area_id: "",
    area_name: "",
    is_new_version: 0,
  });
  const [visible, setVisible] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  let logoRef = useRef();
  let business_license_ref = useRef();
  let upFile = (type) => {
    let formData = new FormData();
    formData.append(
      "file",
      type == 1
        ? logoRef.current.files[0]
        : business_license_ref.current.files[0]
    );
    formData.append("sn", localStorage.getItem("sn"));
    formData.append("utoken", localStorage.getItem("utoken"));
    Toast.show({
      duration: 0,
      icon: "loading",
      content: "上传中...",
    });
    axios.uploadFile("/Api/JuTwitter/Upload/index", formData).then((res) => {
      Toast.clear();
      if (res.code === 0) {
        if (type == 1) {
          setState({
            ...state,
            store_logo: res.result.path,
          });
        } else {
          setState({
            ...state,
            business_license: res.result.path,
          });
          //   axios
          //     .post("/Api/JuTwitter/VerifyBusinessLicense/verify", {
          //       business_license: res.result.path,
          //     })
          //     .then((verifyRes) => {
          //       if (verifyRes.code == 0) {
          //         if (verifyRes.result.ErrorCode == 0) {
          //           setState({
          //             ...state,
          //             business_license: res.result.path,
          //           });
          //         } else {
          //           Toast.show({
          //             content: "请上传正确的营业执照",
          //           });
          //         }
          //       } else {
          //         Toast.show({
          //           content: verifyRes.msg,
          //         });
          //       }
          //     });
        }
      } else {
        Toast.show({
          content: res.msg,
        });
      }
    });
  };
  let confirm = ({ ids, name }) => {
    setState({
      ...state,
      province_id: ids[0],
      city_id: ids[1],
      area_id: ids[2],
      area_name: name,
      store_site: name.replace(/-/gi, ""),
    });
    setVisible(!1);
  };
  let getInfo = () => {
    axios
      .post("/Api/JuTwitter/StoreSettled/info", {
        sn: localStorage.getItem("sn"),
        utoken: localStorage.getItem("utoken"),
      })
      .then((res) => {
        if (res.code === 0) {
          if (res.result && search.get("sn")) {
            navigate("/reviewProgress", { state: res.result.status });
          }
          if (res.result) {
            setState(res.result);
          }
        } else {
          Modal.show({
            bodyStyle: { padding: "20px 0 0 0" },
            content: res.msg,
            closeOnMaskClick: !0,
            showCloseButton: !0,
          });
        }
      });
  };
  let submit = (style) => {
    try {
      if (!state.merchant_name) throw "请输入店铺名称";
      if (GetCharLength(state.merchant_name) > 16)
        throw "店铺名称不可超过8个字";
      if (!state.contact_name) throw "请输入店长姓名";
      if (!state.store_phone) throw "请输入店长电话";
      if (!state.store_site) throw "请输入店铺地址";
      if (!state.province_id) throw "请选择所属区域";
      if (!state.store_logo) throw "请添加店铺logo";
      if (!state.business_license) throw "请添加经营资质";
      axios
        .post("/Api/JuTwitter/StoreSettled/submit", {
          utoken: localStorage.getItem("utoken"),
          sn: localStorage.getItem("sn"),
          merchant_name: state.merchant_name,
          contact_name: state.contact_name,
          store_phone: state.store_phone,
          store_site: state.store_site,
          store_logo: state.store_logo,
          business_license: state.business_license,
          province_id: state.province_id,
          city_id: state.city_id,
          area_id: state.area_id,
          is_new_version: state.is_new_version,
        })
        .then((res) => {
          if (res.code === 0) {
            Toast.show({
              icon: "success",
              content: "提交成功",
            });
            if (!style) navigate("/reviewProgress", { state: 1 });
          } else {
            Toast.show({
              content: res.msg,
            });
          }
        });
    } catch (e) {
      Toast.show({
        content: e,
      });
    }
  };
  let submitPay = () => {
    submit(1);
    try {
      if (!state.merchant_name) throw "请输入店铺名称";
      if (GetCharLength(state.merchant_name) > 16)
        throw "店铺名称不可超过8个字";
      if (!state.contact_name) throw "请输入店长姓名";
      if (!state.store_phone) throw "请输入店长电话";
      if (!state.store_site) throw "请输入店铺地址";
      if (!state.province_id) throw "请选择所属区域";
      if (!state.store_logo) throw "请添加店铺logo";
      if (!state.business_license) throw "请添加经营资质";
      axios
        .post("/Api/JuTwitter/StoreSettled/renewPay", {
          utoken: localStorage.getItem("utoken"),
          sn: localStorage.getItem("sn"),
        })
        .then((res) => {
          if (res.code == 0) {
            window.location.replace(res.result.payUrl);
          } else {
            Toast.show({
              content: res.msg,
            });
          }
        });
    } catch (e) {}
  };
  let authFn = () => {
    let path = window.location.href;
    // wx24a467f22dd9bc98
    let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx24a467f22dd9bc98&component_appid=wx3e81c0bf58ee455c&redirect_uri=${encodeURIComponent(
      path
    )}&response_type=code&scope=snsapi_userinfo#wechat_redirect`;
    window.location.replace(url);
  };
  let getUtoken = () => {
    let obj = handleLocationPath();
    axios
      .post("/Api/WxShop/WechatUser/getUtoken", {
        code: obj.code,
        uniacid: 3,
      })
      .then((res) => {
        if (res.code == 0) {
          localStorage.setItem("utoken", res.result.utoken);
          getInfo();
          if (Number(localStorage.getItem("is_need_pay"))) {
            getPayPrice();
          }
        } else {
          Toast.show({
            content: res.msg,
          });
        }
      });
  };
  let getPayPrice = () => {
    axios
      .post("/Api/JuTwitter/StoreSettled/index", {
        sn: localStorage.getItem("sn"),
        utoken: localStorage.getItem("utoken"),
      })
      .then((res) => {
        if (res.code == 0) {
          set_store_settle_in_annual_fee(res.result.store_settle_in_annual_fee);
        } else {
          Toast.show({
            content: res.msg,
          });
        }
      });
  };
  useEffect(() => {
    document.title = "添加商家";
    if (search.get("sn")) localStorage.setItem("sn", search.get("sn"));
    if (search.get("is_need_pay"))
      localStorage.setItem("is_need_pay", search.get("is_need_pay") || 0);
    if (!search.get("code") && !localStorage.getItem("utoken")) {
      authFn();
    }
    if (!localStorage.getItem("utoken")) {
      // 已经授权过了
      getUtoken();
    } else {
      if (Number(localStorage.getItem("is_need_pay"))) {
        getPayPrice();
      }
      getInfo();
    }
  }, []);
  return (
    <div className={style.content}>
      <div className={style.formInfo}>
        <div className={style.formItem}>
          <div className={style.title}>
            <span>*</span>
            店铺名称
          </div>
          <div className={style.put}>
            <Input
              placeholder="请输入店铺名称"
              value={state.merchant_name}
              onChange={(e) => setState({ ...state, merchant_name: e })}
            />
          </div>
        </div>
        <div className={style.formItem}>
          <div className={style.title}>
            <span>*</span>
            商家姓名
          </div>
          <div className={style.put}>
            <Input
              placeholder="请输入店长姓名"
              value={state.contact_name}
              onChange={(e) => setState({ ...state, contact_name: e })}
            />
          </div>
        </div>
        <div className={style.formItem}>
          <div className={style.title}>
            <span>*</span>
            商家电话
          </div>
          <div className={style.put}>
            <Input
              placeholder="请输入店长电话"
              value={state.store_phone}
              onChange={(e) => setState({ ...state, store_phone: e })}
            />
          </div>
        </div>
        <div className={style.formItem}>
          <div className={style.title}>
            <span>*</span>
            所属区域
          </div>
          <div
            className={`${style.put} ${style.address}`}
            onClick={() => setVisible(true)}
          >
            {state.area_name ? (
              <span>{state.area_name}</span>
            ) : (
              <Input placeholder="请选择所属区域" disabled />
            )}
            <RightOutline fontSize={15} color="#333" />
            <City
              visible={visible}
              cancel={setVisible}
              confirm={confirm}
              type={2}
            ></City>
          </div>
        </div>
        <div className={style.formItem}>
          <div className={style.title}>
            <span>*</span>
            店铺地址
          </div>
          <div className={`${style.put} ${style.address}`}>
            <div style={{ flex: 1 }}>
              <Input
                placeholder="请输入店铺地址"
                value={state.store_site}
                onChange={(e) => setState({ ...state, store_site: e })}
              />
            </div>
          </div>
        </div>

        <div className={style.formItem} style={{ border: 0 }}>
          <div className={style.title}>
            <span>*</span>
            店铺logo
            <span style={{ fontWeight: 400, color: "#999" }}>
              （务必上传店铺门头照）
            </span>
          </div>
          <div className={style.addLogo}>
            <div className={style.demonstration}>
              <Image src="https://staticfile.cnweisou.net/Upload/images/20220928/38b1bdeefbffd0e6d0c7476c5639c31f-未标题-1.png" />
              <div>店铺logo上传示范</div>
            </div>
            <div className={style.upLogo}>
              {state.store_logo ? (
                <div className={style.logo}>
                  <Image src={state.store_logo} />
                  <div
                    className={style.close}
                    onClick={() => setState({ ...state, store_logo: "" })}
                  >
                    <CloseCircleFill color="#ff5a01" fontSize={20} />
                  </div>
                </div>
              ) : (
                <div className={style.add}>
                  <Image src={addImg} />
                  <div>添加</div>
                  <input
                    ref={logoRef}
                    type="file"
                    accept="image/png,image/jpeg,image/jpg"
                    onChange={() => upFile(1)}
                  />
                </div>
              )}
              <div className={style.tips}>建议尺寸：300*300</div>
            </div>
          </div>
        </div>
        <div className={style.formItem} style={{ border: 0 }}>
          <div className={style.title}>
            <span>*</span>
            经营资质
          </div>
          <div
            className={style.business_license}
            style={{
              backgroundImage: "url(" + state.business_license + ")",
              border: state.business_license ? 0 : "",
            }}
          >
            {state.business_license ? (
              <div
                className={style.close}
                onClick={() => setState({ ...state, business_license: "" })}
              >
                <CloseCircleFill color="#ff5a01" fontSize={20} />
              </div>
            ) : (
              <div className={style.add_business_license}>
                <Image src={addImg} />
                <div>添加资质照片</div>
                <input
                  ref={business_license_ref}
                  type="file"
                  accept="image/png,image/jpeg,image/jpg"
                  onChange={() => upFile(2)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className={style.btn}
        onClick={
          !search.get("is_need_pay") ? () => submit() : () => submitPay()
        }
      >
        {!search.get("is_need_pay")
          ? "确认提交"
          : `确认支付 ¥${store_settle_in_annual_fee}`}
      </div>
    </div>
  );
}

export default Activation;
